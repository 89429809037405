.servicecontent-container {
    padding: 50px 0;
    background-color: #ebfbff;
  }
  
  .objectives-heading {
    font-size: 1.8rem;
    color: #003a70;
    text-align: center;

  }
  
  .objective-card-carousel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .objective-card {
    width: 300px;
    margin: 20px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .objective-card:hover {
    transform: translateY(-5px);
  }
  
  .objective-card-body {
    text-align: center;
  }
  
  .objective-card-title {
    font-size: 1.5rem;
    color: #01579E;
  }
  
  .objective-card-text {
    font-size: 1rem;
    color: #7a7a7a;
  }
  
  .objectives-icon {
    margin-right: 10px;
    color: #1a76d1;
  }
  