.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.contact-section h2 {
  text-align: center;
  color: #007c9d;
}

.contact-description {
  max-width: 600px;
}

.contact-description ul {
  list-style: none;
  padding: 0;
}
.contact-description p{
  font-size: 18px;
}

.contact-description li {
  margin-bottom: 10px;
  font-size: 18px;
}

.contact-form {
  max-width: 600px;
}

.contact-form h1{
  color: #007c9d;
}

.contact-form form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.contact-form label {
  flex: 0 0 100%;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  font-size: 20px;
  padding: 10px;
}

.contact-form textarea {
  height: 100px;
}

.button {
  align-items: center;
  appearance: none;
  background-color: #007c9d;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button :active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

.validation-message {
  color: red;
  margin-top: 5px;
}

@media (max-width: 600px) {
  .contact-section {
    padding: 0 20px; /* Adjust padding for smaller screens */
  }

  .contact-description,
  .contact-form {
    max-width: 100%; /* Set max-width to 100% for smaller screens */
  }
}