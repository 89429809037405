.image-section-con {
  position: relative;
  height: 30vh; /* 50% of the viewport height */
  background-color: #007c9d;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #001e3f;
}

.image-section-con h2 {
  font-weight: 700;
  font-size: 3vw; /* 3% of the viewport width */
  color: aliceblue;
}

.image-section-con img {
  max-height: 50%; /* 50% of the parent container's height */
  max-width: 50%; 
  width: 100px;/* 50% of the parent container's width */
  margin-left: 20px;
}

@media (max-width: 767px) {
  .image-section-con {
    height: 20vh; /* Adjust height for smaller screens */
  }
  
  .image-section-con h2 {
    font-size: 5vw; /* Increase font size for smaller screens */
  }
}

