/* Footer.css */
  .footer {
      background-color: black;
      color: #fff;
      padding: 30px 20px;
    }
    
    .footer-content {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
    }
    
    .footer-column {
      margin-bottom: 20px;
      margin: 40px 0px 0px 40px;
    }
    
    .footer-column h3 {
      font-size: 19px;
      margin-bottom: 10px;
      margin-left: 0px;
      color: #ed803a;
    }

    .footer-column .footer-a{
      display: block; /* Display anchor tags as blocks to occupy full width */
      margin-bottom: 5px; /* Add margin bottom for spacing between links */
      text-decoration: none; /* Remove underline from links */
      color: #fff; /* Set link color */
      cursor: pointer;
    }
    
    .footer-column .footer-a:hover{
      color: #ed803a;
    }
    
    .social-icons a {
      color: #fff;
      font-size: 20px;
      margin-right: 10px;
    }
    
    .social-icons i{
      color: #fff;
      font-size: 22px;
      padding: 10px;
    }


    .footer-bottom {
      margin-top: 20px;
      text-align: center;
      font-size: 18px;
    }
    
    .footer-separator {
      border-bottom: 1px solid #999;
      margin-bottom: 10px;
    }
  
    /* Parallax */
    .parallax-footer {
      position: relative;
      overflow: hidden;
      background-color: #060822;
      
  
    }
  
    .parallax-footer::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #060822;
      /* Replace with the actual path to your image */
      background-size: cover;
      background-position: center;
      z-index: -1;
    }
    
    .parallax-footer .footer-content {
      position: relative;
      z-index: 1;
    }
    
  
    /* Responsive styles */
  @media screen and (max-width: 768px) {
    .footer-content {
      display: flex;
      flex-wrap: wrap;
    }
    
    .footer-column {
      width: 100%;
      margin-bottom: 20px;
    }
    
    .social-icons {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    
    .footer-separator {
      display: none;
    }
    .social-icons i{
      align-items: start;
      
    }
  }
    
    
    