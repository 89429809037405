/* Base styles */
.hero-section {
  position: relative;
}

.hero-image {
  position: relative;
  overflow: hidden;
}

.hero-image img {
  width: 100%;
  height: auto;
  display: block;
}

.hero-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.hero-text h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.hero-text p {
  font-size: 1.2rem;
}

@media (max-width: 600px) {
  .hero-text h1 {
    font-size: 1.1rem;
  }

  .hero-text p {
    font-size: 0.6rem;
  }

  .hero-text {
    padding: 0 10px; /* Add some padding to prevent text from touching the edges */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .hero-text h1 {
    font-size: 2rem;
  }

  .hero-text p {
    font-size: 1.4rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .hero-text h1 {
    font-size: 3.5rem;
  }

  .hero-text p {
    font-size: 1.6rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .hero-text h1 {
    font-size: 4rem;
  }

  .hero-text p {
    font-size: 1.8rem;
  }
}
