
.sticky-call-icon {
    position: fixed;
    bottom: 20px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */
    z-index: 1000; /* Ensure it's above other content */
    background-color: #ed803a; /* Change color as needed */
    color: #ffffff;
    width: 50px; /* Adjust width and height as needed */
    height: 50px;
    border-radius: 50%;
    text-align: center;
    line-height: 50px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .sticky-call-icon i {
    font-size: 24px; /* Adjust icon size as needed */
  }
  
  .sticky-call-icon a {
    color: inherit;
    text-decoration: none;
  }
  
  .sticky-call-icon:hover {
    background-color: #0056b3; /* Change color on hover as needed */
  }
  