/* Service.css */

  .counting-container {
      max-width: 1200px;
      margin: 0px auto;
      padding: 10px;
    }
  .counting-container h1{
      color: #fff;
      font-weight: 700;
      text-align: center;
      padding: 20px;
      
  }

  .counting-container p{
    color: #fff;
  }

  .parallax{
    background-color: #007c9d;
  }
  
  
    .count-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    
    .count-card {
      width: calc(25% - 20px);
      border: 2px solid #ccc;
      border-radius: 8px;
      padding: 20px;
      margin-bottom: 20px;
      text-align: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease;
      background-color: #01C9C9;
    }
    
    .count-card:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    }
    
    .count-card span {
      font-size: 2rem;
      color: #fff;
      font-weight: bold;
    }
    
    .count-card p {
      margin-top: 10px;
      font-size: 1rem;
      color: #fff;
    }
    
    .icon-circle {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 20px;
    }
    
    .icon-circle i {
      font-size: 2rem;
      color: #fff;
    }
    
    /* Responsive Styles */
    
    @media (max-width: 992px) {
      .count-card {
        width: calc(50% - 20px);
      }
      
      .icon-circle {
        width: 60px;
        height: 60px;
      }
      
      .icon-circle i {
        font-size: 1.5rem;
      }
    }
    
    @media (max-width: 576px) {
      .count-card {
        width: calc(100% - 20px);
      }
      
      .icon-circle {
        width: 50px;
        height: 50px;
      }
      
      .icon-circle i {
        font-size: 1.2rem;
      }
    }
    