/* TopDesc.css */

/* Base styles for contact info section */
.contact-info {
    display: flex;
    background-color: #007c9d;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: space-between;
    align-items: center;
    padding: 5px;
  }
  
  /* Phone and email styles */
  .phone,
  .email {
    display: flex;
    align-items: center;
    margin: 20px;
    margin-bottom: 10px; /* Add margin between phone/email and social icons */
  }
  
  .phone i,
  .email i {
    margin-right: 5px;
    color: aliceblue;
  }
  
  .phone span,
  .email span,
  .email a {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
  }
  
  .email a {
    text-decoration: none;
  }
  
  /* Social icons styles */
  .social-icons-1 {
    display: flex;
    justify-content: flex-end; /* Align social icons to the right on larger screens */
    flex-grow: 1; /* Allow social icons to grow and take up remaining space */
    margin-right: 50px;
  }
  
  .social-icons-1 a {
    margin-left: 10px;
    font-size: 18px;
    color: #fff; /* Change color as needed */
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-icons-1 a:hover {
    color: #007bff; /* Change color on hover as needed */
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    /* Adjust font size for smaller screens */
    .phone span,
    .email span,
    .email a {
      font-size: 12px; /* Adjust font size for smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    /* Adjust font size and margins for even smaller screens */
    .phone,
    .email {
      margin: 10px;
      margin-bottom: 5px;
    }
  
    .phone span,
    .email span,
    .email a {
      font-size: 10px;
    }
  }
  