/* Responsive CSS for .icon-container */
.icon-container {
  margin: 0px auto;
  padding: 50px;
  border: 1px solid #ccc;
  border-radius: 0px;
  background-color: #007c9d;
}

.icon-title {
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fff;
}

.icon-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.icon-con {
  text-align: center;
  margin: 10px;
  padding: 35px;
  background-color: #fff;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.icon-con:hover {
  transform: translateY(-2px); /* Move the element slightly upwards on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box-shadow on hover */
  color: #fff;
  background-color: #35B6B4;

}

.icon-con .icons {
  font-size: 25px;
  color:  #007c9d;
}

.text-title {
  font-size: 18px;
  color:  #007c9d;
}

/* Media Queries for responsiveness */

/* For smaller devices */
@media screen and (max-width: 768px) {
  .icon-title {
    font-size: 28px;
  }

  .icon-con {
    padding: 20px;
  }

  .icon-con .icons {
    font-size: 20px;
  }

  .text-title {
    font-size: 16px;
  }
}

/* For extra small devices */
@media screen and (max-width: 576px) {
  .icon-title {
    font-size: 24px;
  }

  .icon-con {
    padding: 15px;
  }

  .icon-con .icons {
    font-size: 18px;
  }

  .text-title {
    font-size: 14px;
  }
}
