/* Appointment.css */

.appointment-section {
    padding: 2rem;
    background-color: #ebfbff;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .appointment-section h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .appointment-form {
    display: flex;
    flex-direction: column;
  }
  
  .appointment-form label {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .appointment-form input,
  .appointment-form select {
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .appointment-form .validation-message {
    color: red;
    font-size: 0.875rem;
    margin-top: -0.5rem;
    margin-bottom: 1rem;
  }
  
  .appointment-form button {
    padding: 0.75rem;
    background-color: #007c9d;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .appointment-form button:hover {
    background-color: #0056b3;
  }
  
  .status-message {
    font-size: 1rem;
    text-align: center;
    margin-top: 1rem;
    color: green;
  }
  
  /* Responsive Design */
  
  @media (max-width: 768px) {
    .appointment-section {
      padding: 1rem;
    }
  
    .appointment-form input,
    .appointment-form select {
      font-size: 0.875rem;
    }
  
    .appointment-form button {
      font-size: 0.875rem;
    }
  }
  