.doctor-section {
    padding: 20px;
  }
  
  .doctor-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .doctor-card {
    width: 250px;
    margin: 20px;
    padding: 20px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .doctor-card img {
    width: 100%;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .search-filter {
    margin-bottom: 20px;
    margin: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .search-filter input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 200px;
  }
  
  .search-filter select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    font-size: 15px;
    margin-right: 30px;
  }

  .search-filter option{
    font-size: 15px;
  }
  
  /* Media Query for Tablet */
@media screen and (max-width: 768px) {
  .doctor-card {
    width: calc(50% - 40px); /* Two cards per row with some margin */
  }

  .search-filter {
    margin: 0 10px; /* Adjust margin */
  }
  .search-filter select{
      font-size: 15px;
  }
}

/* Media Query for Mobile */
@media screen and (max-width: 480px) {
  .doctor-card {
    width: calc(100% - 40px); /* Single card per row with some margin */
  }

  .search-filter {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-filter select {
    margin-top: 10px; /* Adjust margin */
    width: 100%; /* Adjust width */
    font-size: 15px;
  }

  .search-filter input[type="text"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    width: 300px;
  }

  .search-filter option{
    font-size: 15px;
  }
}
 
  