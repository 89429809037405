/* Navbar.css */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #f8f9fa;
  color: #007c9d;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0; 
  height: 70px;
  z-index: 1000; 
}

.navbar__logo img {
  height: 60px;
  margin: 50px;
}


.navbar__menu {
  display: flex;
  align-items: center;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger-menu .line {
  width: 30px;
  height: 4px;
  background-color: #003a70;
  margin: 6px 0;
  transition: 0.4s;
}

.hamburger-menu.open .line:nth-child(1) {
  transform: rotate(-40deg) translate(-5px, 6px);
}

.hamburger-menu.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open .line:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

.navbar__links {
  display: flex;
  list-style: none;
}

.navbar__links li {
  margin: 0 1rem;
  position: relative;
}

.navbar__links a {
  text-decoration: none;
  color: #003a70;
  font-family: 'Poppins', sans-serif;
}

.navbar__links a:hover{
  color: #ed803a;
}

.appointment-button {
  background-color: #007c9d;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.dropdown {
  position: relative;
}

.dropbtn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  color: #003a70;
}
.dropbtn:hover{
  color: #ed803a;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    margin-left: -20px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    padding: 10px;
    width: 640px;
    column-count: 3;
    column-gap: 10px;

}

.dropdown-content a {
  color: #003a70;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
  color: #ed803a;
}

.dropdown .dropbtn:focus + .dropdown-content,
.dropdown-content:hover {
  display: block;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .navbar__links {
    display: none;
    flex-direction: column;
    align-items: center;
    background-color: #f8f9fa;
    width: 100%;
    padding: 0;
    position: absolute;
    top: 70px;
    left: 0;
    z-index: 999;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .navbar__links.open {
    display: flex;
  }

  .navbar__links li {
    margin-left: 0;
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
    width: 100%;
    text-align: center;
  }

  .navbar__links .appointment-button {
    display: block;
    width: 80%;
    margin: 20px auto;
    text-align: center;
  }

  .dropdown {
    position: static;
  }

  .dropdown-content {
    display: none;
    position: static;
    width: 100%;
    margin-left: 0;
    padding: 0;
    box-shadow: none;
    column-count: 1;
    border-top: 1px solid #ccc;
  }

  .dropdown-content a {
    width: 100%;
    max-width: 360px;
    padding: 10px 20px;
    text-align: center;
  }

  .dropdown:hover .dropdown-content {
    display: none;
  }

  .dropdown:hover .dropbtn i.fa-chevron-up {
    animation: none;
    transform: rotate(0deg);
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
  }

  .hamburger-menu .line {
    width: 30px;
    height: 4px;
    background-color: #003a70;
    margin: 6px 0;
    margin-right: 30px;
    transition: 0.4s;
  }
}
