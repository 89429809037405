.heroim-section {
    background-color: #ebfbff; /* Example background color */
    padding: 2rem;
  }
  
  .hero-section__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1200px; /* Adjust max-width as needed */
    margin: 0 auto;
  }
  
  .hero-section__text {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .hero-section__text h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #01C9C9;
  }
  
  .hero-section__image {
    width: 100%;
    text-align: center;
  }
  
  .hero-section__image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .hero-section__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .hero-section__icon h3 {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  
  .hero-section__icon p {
    margin-bottom: 0;
    
  }
  
  
  /* Media Query for responsive design */
  @media (min-width: 768px) {
    .hero-section__content {
      flex-direction: row;
      align-items: flex-start;
    }
  
    .hero-section__text {
      flex: 1;
      text-align: left;
      margin-right: 2rem;
      margin-bottom: 0;
    }
  
    .hero-section__image {
      flex: 1;
      text-align: center;
    }
    .hero-section__icon {
      flex-direction: row;
      justify-content: center;
      text-align: left;
    }
  
    .hero-section__icon h3 {
      margin-top: 0;
      margin-left: 10px;
    }
  
    .hero-section__icon p {
      margin-left: 10px;
    }
  }
  