.blog-section {
  padding: 20px;
  margin: 20px;
}

.blog-section h1 {
  font-size: 30px;
  text-align: center;
  color: #007c9d;
  font-weight: 600;
}

.blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto; /* Center the container */
  max-width: 1200px; /* Limit the container width */
}

.blog-card {
  width: calc(50% - 20px);
  margin-bottom: 20px;
  border: 2px solid #007c9d;
  border-radius: 8px;
  overflow: hidden;
}

.blog-card img {
  width: 100%;
  height: auto;
}

.blog-info {
  padding: 20px;
}

.blog-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.blog-summary {
  font-size: 1rem;
  margin-bottom: 20px;
}

.read-more {
  display: inline-block;
  color: #007bff;
  text-decoration: none;
}

/* Media Queries */
@media screen and (max-width: 768px) {
  .blog-container {
    margin: 0 20px; /* Adjust margin for smaller screens */
  }
  
  .blog-card {
    width: calc(100% - 10px); /* Adjust card width for smaller screens */
  }
}
